import {useState, useEffect} from 'react';
import {useSelector, useDispatch} from "react-redux"; // get the state to dispatch the register function in the Authslice (useselector will select the state (is error is loading ..) useDispatch will dispatch the state register state )
import {useNavigate} from "react-router-dom";
import {toast} from "react-toastify";


// import {Link} from "react-router-dom";
import {FaCheck,FaTimes} from "react-icons/fa";
import ReCAPTCHA from "react-google-recaptcha";
import Spinner from "../components/Spinner";
import {contact, reset} from "../features/auth/authSlice"; //eported from the reducers


//Check warning sign for form fields
const EMAIL_REGEX = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
const NAME_REGEX = /^[a-zA-Z]{3,23}$/;
const TEXT_REGEX = /^[a-zA-Z0-9\s,'-]{10,255}$/;


function Contact(){
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {isLoading, isError, isSuccess, message} = useSelector((state) => state.auth);

  const [firstname, setFirstName] = useState('');
  const [lastname, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [text, setText] = useState('');
  const [recaptchaToken, setrecaptchaToken] = useState(null);

  const onChange = (e) => {

    if (e.target.name === "firstname") {
      setFirstName(e.target.value)

    }else if (e.target.name === "lastname") {
      setLastName(e.target.value)
    }else if (e.target.name === "email") {
      setEmail(e.target.value)
    }else if (e.target.name === "text") {
      setText(e.target.value)
    }else if (e.target.name === "recaptcha") {
      setrecaptchaToken(e.target.value)
    }

};

const [validEmail,setValidEmail] = useState(false) // boolean to check if name validates or not
const [validText, setValidText] = useState(false);
const [validFirstName, setValidFirstName] = useState(false);
const [validLastName, setValidLastName] = useState(false);

useEffect(() => {
  setValidFirstName(NAME_REGEX.test(firstname));
  setValidLastName(NAME_REGEX.test(lastname));
  setValidEmail(EMAIL_REGEX.test(email));
  setValidText(TEXT_REGEX.test(text));

}, [firstname, lastname,email,text])



useEffect(()=>{

  //set focus when the component load to set the focus on username
  if (isError) {

    toast.error(message);

  }

  if (isSuccess) {
    toast.success(message);

    navigate("/");
  }

  //reset the state
  dispatch(reset())

}, [isError, isSuccess, message, navigate, dispatch])



const formdata = new FormData();

formdata.append('firstname', firstname);
formdata.append('lastname', lastname);
formdata.append('email', email);
formdata.append('text', text);
formdata.append('recaptcha', recaptchaToken);

const onCaptchaChange = (value) => {
  setrecaptchaToken(value);
};

const onSubmit = (e) => {
  e.preventDefault();
  // console.log(formdata);

  const contactData = {
  firstname,
  lastname,
  email,
  text,
  recaptcha: recaptchaToken,
};

  dispatch(contact(contactData))

}

if (isLoading) {

  return <Spinner />

}

return  (
  <>

  <div className="wrapper_contact">

  <section className="heading">
  <h3>Contact us</h3>
  </section>

  <section className="form">


    <form onSubmit={onSubmit} encType='multipart/form-data'>

    <div className="form-group">
      <label htmlFor="firstname">First Name
      <FaCheck className={validFirstName ? "valid" : "hide"} />
      <FaTimes className={!validFirstName && firstname ? "invalid" : "hide"} /></label>
      <input
      type="text"
      className="form-control"
      id="firstname"
      name="firstname"
      value={firstname}
      onChange={onChange}
      required
      />
    </div>

    <div className="form-group">
      <label htmlFor="lastname">Last Name
      <FaCheck className={validLastName ? "valid" : "hide"} />
      <FaTimes className={!validLastName && lastname ? "invalid" : "hide"} /></label>
      <input
      type="text"
      className="form-control"
      id="lastname"
      name="lastname"
      value={lastname}
      onChange={onChange}
      required
      />
    </div>

    <div className="form-group">
      <label htmlFor="email">E-mail Address
      <FaCheck className={validEmail ? "valid" : "hide"} />
      <FaTimes className={!validEmail && email ? "invalid" : "hide"} /></label>
      <input
      type="email"
      className="form-control"
      id="email"
      name="email"
      value={email}
      onChange={onChange}
      required
      />
    </div>

    <div className="form-group">
      <label htmlFor="text">Your Message
      <FaCheck className={validText ? "valid" : "hide"} />
      <FaTimes className={!validText && text ? "invalid" : "hide"} /></label>
      <textarea
      className="form-control mb-2"
      type="textarea"
      id="text"
      name="text"
      value={text}
      onChange={onChange}
      required
      >
      </textarea>
    </div>


    <div className="form-group">

    <ReCAPTCHA
    sitekey="6LcaTu8pAAAAAEh-mAhlPSerEc0Y9KoAWHqfCeGN"
    name="recaptcha"
    onChange={onCaptchaChange}
    />
      <button className="contactbutton btn btn-warning mt-2" type="submit">Submit</button>
    </div>

    </form>

    </section>

    </div>

    </>
)
};

export default Contact
