import {useEffect} from "react";
import {useSelector,useDispatch} from "react-redux";
import {Link,useNavigate} from "react-router-dom";
import Spinner from "../components/Spinner";
import {toast} from "react-toastify";
// import logo from "../logos/logo2_192.png";
// import {FaCheckCircle,FaDollarSign} from "react-icons/fa";




import {getConversations,resetMessage} from "../features/messages/messageSlice";
import {reset,logout} from "../features/auth/authSlice";

function ViewMessages(){

  const navigate = useNavigate();
  const dispatch = useDispatch();


  const {user} = useSelector((state => state.auth));
  const {conversations,isLoading,isError,isSuccess,message} = useSelector((state => state.messages));

  useEffect(()=>{

    if (isError) {

      toast.error(message);
      // console.log(message);
      // throw new Error(message);
      if (message.match(/(Token expired|Not authorized|no token)/g)) {

        dispatch(logout());
        navigate('/login', { replace: true });

      }


      dispatch(resetMessage());
    }

    if (!user) {
        dispatch(reset())
        navigate('/login', { replace: true });
    }







    if (isSuccess) {

      // toast.success("Products loaded!")

      // navigate("/dashboard")
        // dispatch(reset())
    }
    dispatch(getConversations());
    // return () => {
    //    dispatch(reset())
    //  }
          // console.log(usermessages);

  }, [user,navigate,isSuccess,isError,message,dispatch])

  // return(
  //   <div className="product">
  //     {/*<div>{new Date(product.productDate).toLocalString("en-US")}</div>*/}
  //     <h2>{product.productName}</h2>
  //   </div>
  //
  // )


    if (isLoading) {
      return <Spinner />
    }

    // if (usermessages) {
    //
    // }


    //adding here to test
    // <ProductItem key={product.productid} product={product} />

    return (
      <>
      <section className="container">
        {conversations.length > 0 ? (
          <div className="row row-cols-1 row-cols-md-2 g-4">
          {conversations && conversations.map((conversation) => {
            let messages = JSON.parse(conversation.messages);
            // Check if messages is an array
            if (!Array.isArray(messages)) {
              messages = [messages];
            }
            return(
                <div className="col" key={conversation.messageid}>
                  <div className={conversation && messages && messages.some(message => message.viewed === false) ? "card mx-auto text-center opacity-100" : "card mx-auto text-center opacity-50"}>
                    <div className="card-header">
                      <div className="messageRead-Unread">
                        {conversation && messages && messages.some(message => message.viewed === false) ? (
                          <p className="card-text text-start">UNREAD</p>
                        ) : (
                          <p className="card-text text-start">READ</p>
                        )}
                      </div>
                      <p className="card-title"><strong>From</strong> {`${conversation.fromusername}`}</p>
                      <p className="card-text"><strong>For</strong> {`${conversation.forProductname}`}</p>
                      {conversation.fromusername === user.username ? (
                        <p className="card-text text-start text-primary font-italic">This is a message you sent.</p>
                      ) : (
                        <p className="card-text text-start text-success font-italic">This is a message you received.</p>
                      )}
                    </div>
                    <small className="text-muted">{new Date(messages[0].timestamp).toLocaleString('en-US')}</small>
                    <Link className="btn btn-secondary stretched-link mt-1" to={"/viewMessage/" + conversation.messageid}>View this conversation</Link>
                  </div>
                </div>
              );
            })}
          </div>
          ) : (
            <h3>You don't have any conversations yet.</h3>
          )}
        </section>
      </>
    );
  }

export default ViewMessages
