import axios from "axios";

const API_URL = "https://us-central1-jinglebackend.cloudfunctions.net/api/api/messages/";

// Get all conversations of a user
const getConversations = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    }
  }

  try {
    const response = await axios.get(API_URL, config);
    return response.data;
  } catch (e) {
    throw new Error(e.response.data.message || e.message);
  }
}

// Start a new conversation
const startConversation = async (conversationData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }

  try {
    const response = await axios.post(API_URL, conversationData, config);
    return response.data;
  } catch (e) {
    throw new Error(e.response.data.message || e.message);
  }
}

// Get a conversation by ID
const getConversation = async (conversationId, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    }
  }

  try {
    const response = await axios.get(API_URL + conversationId, config);
    return response.data;
  } catch (e) {
    throw new Error(e.response.data.message || e.message);
  }
}

// Respond to a conversation
const respondToConversation = async ([conversationId, messageData], token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    }
  }

  try {
    const response = await axios.put(API_URL + conversationId, messageData, config);
    return response.data;
  } catch (e) {
    throw new Error(e.response.data.message || e.message);
  }
}

const messageService = {
  getConversations,
  startConversation,
  getConversation,
  respondToConversation
}

export default messageService;
