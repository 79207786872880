
import {FaPlusCircle,FaMinusCircle} from "react-icons/fa";
import {useState} from 'react';

const accordionData = [
  {
    id: "whatsetsusapart",
    key:1,
    title: "Who are we?",
    content: "We are an online swap meet for kids. We call this place “Jingle” because that sounds happy, and because it sounds a lot like the name of the lady who came up with this idea. Your kids can come here when they’re looking for something they need, or when they have used toys and things that they would like to sell. Parents really like Jingle because our kids learn the value of taking care of their stuff."
  },
  {
    id: "whatsetsusapart",
    key:2,
    title: "What we do?",
    content: "We teach your kids how to buy and sell their toys. Jingle matches the buyer with the seller."
  },
  {
    id: "whatsetsusapart",
    key:3,
    title: "Where to begin?",
    content: `Using your email address and your password, go to the dashboard and you’ll see four boxes: (1) add a product, (2) view your products, (3) view/edit your profile, (4) view your messages.

    If you want to sell an item, enter all the important information and price. Pictures are required. If someone wants to buy your item, they’ll message you. If you sell your item to them, you indicate this with the green button. Your screen should indicate that the item has been sold to that person. The buyer and seller exchange text messages to plan a meeting for completing the transaction.

    If you are looking for something to buy, use the “search bar” on the dashboard. When you’ve found an item of interest, click on the picture. After you have entered your contact information, all you need to do is wait for the seller to message you back.

    Read new messages by pressing the big green “Messages” button in your dashboard. Check each day. You wouldn’t want the seller to think you have lost interest.`
  },
  {
    id: "whatsetsusapart",
    key:4,
    title: "Why choose us?",
    content: "Unused toys are no longer taking up valuable room in your closets. Your kids know that by taking good care of their toys, these toys can be used to buy other things in the future. Choose us because we teach kids that there is a value in everything. Somebody else is ready to pay for something that you no longer need. Using Jingle is free, and we Never sell anyone’s contact or purchase information to any other businesses. All communication and transactions are held in strict privacy. We make your kids happy and smarter!"
  }
];


function About(){

  const [toggled, toggle] = useState("");

  // const { title, content } = accordionData;

  return(
    <>
    <section className="aboutus">

    <div className="accordeon" >
      {accordionData.map((e) => (
            <div key={e.key} className={toggled === e.key ? 'accordeonContainer shadow bg-white activatedAccordeon' : 'accordeonContainer shadow inactiveAccordeon'} onClick={() => toggle(e.key)}>


              <div  className="accordeonTitle">

              <h4 className="title">{e.title}</h4>

              {toggled === e.key ? <FaMinusCircle className="minussign"/> : <FaPlusCircle className="plussign"/>}

              </div>

              <div className="content" style={{
                opacity: toggled === e.key ? "1" : "0",
                transition: "ease 1s",
                whiteSpace: "pre-wrap"
              }}>

              {toggled === e.key ? (
                <p>{e.content}</p>
              ) : (
                null
              )
            }

            </div>
              </div>

      ))}
    </div>


    </section>
</>
  )
}

export default About
