import {useState,useEffect,useRef} from "react";
import {useSelector,useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import {FaUser,FaCheck,FaTimes,FaInfoCircle} from "react-icons/fa";
import Spinner from "../components/Spinner";
import {toast} from "react-toastify";

import {viewUserProfile, updateUserProfile,reset,logout,resetProfile} from "../features/auth/authSlice";

const ZIPCODEREGEX = /^[0-9]{5}(?:-[0-9]{4})?$/;



function ViewUserProfile(){

  const navigate = useNavigate();
  const dispatch = useDispatch();



  const {user, userProfile,isLoading,isSuccess,isUpdated, isError, message} = useSelector((state => state.auth));

  //things for errors;
  const errorRef = useRef();

  // const checkID = userProfile.userid;


//view user

  useEffect(()=>{


    if (isError) {

      toast.error(message);
      // console.log(message);
      if (message.match(/(Token expired|Not authorized|no token)/g)) {

        dispatch(logout());
        navigate('/login', { replace: true });

      }

      dispatch(reset())
    }

    // if (isSuccess) {
    //
    // }

    if (isSuccess && isUpdated) {
      setSuccessForm(true);
      dispatch(resetProfile());
      // dispatch(viewUserProfile());
      toast.success("SUCCESS! Profile updated")
      navigate("/viewUserProfile")

    }

    // if (!id) {
    //   navigate("/dashboard")
    //   dispatch(resetProduct())
    // }

    // if (!checkID) {
    //   // navigate("/login")
    //   // dispatch(reset())
    //   // dispatch(reset());
    //   dispatch(resetProfile());
    //   navigate("/dashboard")
    //
    // }

    if (!user) {
        dispatch(reset())
        navigate('/login', { replace: true });
    }



    dispatch(viewUserProfile());


}, [user,navigate,isSuccess,isError,isUpdated,message,dispatch]);

// Initialize userProfileData as an empty object
const [userProfileData, setFormData] = useState({});

useEffect(() => {
  // Check if userProfile is defined
  if (userProfile) {
    // Update userProfileData state
    setFormData({
      firstname: userProfile.firstname,
      lastname: userProfile.lastname,
      city: userProfile.city,
      zipcode: userProfile.zipcode,
      state: userProfile.state
    });
  }
}, [userProfile]); // Dependency array



//formstuff;

// const [userProfileData, setFormData] = useState({
//     firstname: userProfile.firstname,
//     lastname: userProfile.lastname,
//     city: userProfile.city,
//     zipcode: userProfile.zipcode,
//     state: userProfile.state
//   });

  const {firstname,lastname,city,zipcode,state} = userProfileData;

  //state for valid zip

  const [validZIP,setvalidZIP] = useState(false) // boolean to check if name validates or not
  const [zipFocus, setzipFocus] = useState(false)  //boolean to check focus on that input field or not

  //form message
  const [formErrorMsg, setFormErrorMsg] = useState("");
  const [successForm, setSuccessForm] = useState(false);


//use effect for validor
useEffect(() => {
  setvalidZIP(ZIPCODEREGEX.test(zipcode));
}, [zipcode])

//use effect for error form
useEffect(() => {
  setFormErrorMsg('');
}, [zipcode])


  const onChange = (e) => {

    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]:e.target.value //key of whatever the target "name" is (input name prop)

    }))

    };


      const onSubmit = (e) => {
        e.preventDefault();

        const v1 = ZIPCODEREGEX.test(zipcode);
        // const v2 = PWD_REGEX.test(password);
        if (!v1) {
          setFormErrorMsg("Invalid ZIPCODE");
          // toast.error("Invalid Entry");

        }else {

            dispatch(updateUserProfile(userProfileData))
          }
        }






    if (isLoading) {
      return <Spinner />
    }

    return (
      <>
        {successForm ? (
            <section>
                <h1>Success!</h1>
            </section>
        ) : (

      <section className="form">
      <section className="heading">
      <h5><FaUser />User Profile Update</h5>
      </section>


      <input type="email" className="form-control btn btn-block" value={userProfile.email} disabled/>

      <p ref={errorRef} className={formErrorMsg ? "formErrorMsg" : "offscreen"} aria-live="assertive">{formErrorMsg}</p>

      <form onSubmit={onSubmit} encType='multipart/form-data'>


      <div className="form-group">
        <label htmlFor="firstname">First name</label>
        <input
        className="form-control"
        type="text"
        id="firstname"
        name="firstname"
        value={firstname}
        placeholder="Enter your first name"
        onChange={onChange}
        required/>
      </div>

      <div className="form-group">
        <label htmlFor="lastname">Last name</label>
        <input
        className="form-control"
        type="text"
        id="lastname"
        name="lastname"
        value={lastname}
        placeholder="Enter your last name"
        onChange={onChange}
        required/>
      </div>


      <div className="form-group">
        <label htmlFor="zipcode">Zipcode
        <FaCheck className={validZIP ? "valid" : "hide"} />
        <FaTimes className={validZIP || !zipcode ? "hide" : "invalid"} />
        </label>
        <input
        className="form-control"

        type="text"
        inputMode="numeric"

        id="zipcode"
        name="zipcode"
        value={zipcode}
        placeholder="Enter your zipcode"
        onChange={onChange}
        aria-invalid={validZIP ? "false" : "true"}
        aria-describedby="uidnote"
        onFocus={() => setzipFocus(true)}
        onBlur={() => setzipFocus(false)}
        />

        <p id="uidnote" className={zipFocus && zipcode && !validZIP ? "instructions" : "offscreen"}>
        <FaInfoCircle />
        Must be a valid 5 digits US Zipcode.
        </p>

      </div>

      <div className="form-group">
        <label htmlFor="city">City</label>
        <input
        className="form-control"
        type="text"
        id="city"
        name="city"
        value={city}
        placeholder="Enter your city"
        onChange={onChange}
        disabled/>
      </div>

      <div className="form-group">
        <label htmlFor="state">State</label>
        <input
        className="form-control"
        type="text"
        id="state"
        name="state"
        value={state}
        placeholder="Enter your state"
        onChange={onChange}
        disabled/>
      </div>




      <div className="form-group">
        <button disabled={!validZIP ? true : false} className="btn btn-block btn-primary mx-2 mt-3" type="submit">Update Profile</button>
        <button className='btn btn-block btn-danger mx-2 mt-3' onClick={() => navigate("/dashboard")}>
        Cancel
        </button>
      </div>


      </form>
    </section>
  )}
    </>
  )

  }

export default ViewUserProfile
