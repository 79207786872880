import {useEffect} from "react";
import {useSelector,useDispatch} from "react-redux";
import {Link,useNavigate} from "react-router-dom";
import Spinner from "../components/Spinner";
import {toast} from "react-toastify";
// import logo from "../logos/logo2_192.png";
import {FaCheckCircle,FaDollarSign} from "react-icons/fa";
import {FaCircleXmark} from "react-icons/fa6";



import {getApprovalsPage,resetProduct} from "../features/products/productSlice";
import {reset,logout} from "../features/auth/authSlice";

function ApprovalsPageAdmin(){

  const navigate = useNavigate();
  const dispatch = useDispatch();


  const {user} = useSelector((state => state.auth));

  const {products,isLoading,isSuccess, isError, message} = useSelector((state => state.products));

  useEffect(()=>{

    if (isError) {

      toast.error(message);
      // console.log(message);
      // throw new Error(message);
      if (message.match(/(Token expired|Not authorized|no token)/g)) {

        dispatch(logout());
        navigate('/login', { replace: true });

      }


      dispatch(resetProduct());
    }

    if (!user) {
        dispatch(reset())
        navigate('/login', { replace: true });
    }







    if (isSuccess) {

    }
    dispatch(getApprovalsPage());

  }, [user,navigate,isSuccess,isError,message,dispatch])



    if (isLoading) {
      return <Spinner />
    }


    //adding here to test
    // <ProductItem key={product.productid} product={product} />

    return (
      <>
    <section className="container">
    {products.length > 0 ? (
      <div className="row row-cols-1 row-cols-md-2 g-4">
      {products.map((product)=>(
        <div className="col" key={product.productid} product={product}>
          <div className= {product.sold === true ? ("card mx-auto text-center opacity-50"):(" card mx-auto text-center opacity-100")}>
          <div className="card-header">
          {
            product.isReviewed === true ? (
              <p className="card-text text-center text-success"><FaCheckCircle/> Product reviewed: {product.isReviewed}</p>
            ):(
              <p className="card-text text-center text-danger"><FaCircleXmark /> Not Reviewed </p>
            )
          }
          <h5 className="card-title h3">{product.productName}</h5>
          </div>
          <div className="">
          <img src={product.productImages[0]} className="card-img-top rounded bg-light productimg " alt="..."/>
          </div>
              <div className="card-body">
                <p className="card-text text-center">${product.productPrice}</p>
              <div className="card-footer">
              <small className="text-muted">{new Date(product.productDate).toLocaleString('en-US')}</small>
              </div>
            </div>
            <Link className="btn btn-info stretched-link text-white" to={"/viewProduct/" + product.productid}>Display this Product</Link>
          </div>
        </div>
      ))}
      </div>
    ) : (<h3>You don't have any products to review yet.</h3>)
  }
  </section>
    </>
  )
}

export default ApprovalsPageAdmin
