import {BrowserRouter as Router, Routes, Route} from "react-router-dom";
import {ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Header from "./components/Header";
import Footer from "./components/Footer";
import Dashboard from "./pages/Dashboard";
import Login from "./pages/Login";
import Register from "./pages/Register";
import Home from "./pages/Home";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Verify from "./pages/Verify";
import PrivacyPolicy from "./pages/PrivacyPolicy";

///
import ResetPassword from "./pages/ResetPassword"

//product stuff
import ProductForm from "./components/ProductForm";
import ProductItem from "./components/ProductItem";
import ProductSearch from "./components/ProductSearch";
import ProductView from "./components/ProductView";
import ProductUpdate from "./components/ProductUpdate";

//messages
import SendMessage from "./components/SendMessage"
import ViewMessages from "./components/ViewMessages"
import ViewUserMessage from "./components/ViewUserMessage"


//userprofile update
import ViewUserProfile from "./components/ViewUserProfile";

//admin
import ApprovalsPageAdmin from "./components/ApprovalsPageAdmin";
import ReviewsPageAdmin from "./components/ReviewsPageAdmin";

function App() {
  return (
    <>
      <Router>
          <Header />
          <main id="" className="container main">
          <Routes>
          <Route path="/" element={< Home />} />
          <Route path="/about" element={< About />} />
          <Route path="/contact" element={< Contact />} />
          <Route path="/privacypolicy" element={< PrivacyPolicy />} />
          <Route path="/dashboard" element={< Dashboard />} />
          <Route path="/login" element={< Login />} />
          <Route path="/register" element={< Register />} />
          <Route path="/verify" element={< Verify />} />
          <Route path="/resetpassword" element={< ResetPassword />} />
          <Route path="/addProduct" element={< ProductForm />} />
          <Route path="/viewUserProducts" element={< ProductItem />} />
          <Route path="/searchProducts" element={< ProductSearch />} />
          <Route path="/viewProduct/:id" element={< ProductView />} />
          <Route path="/updateProduct/:id" element={< ProductUpdate />} />
          <Route path="/viewUserProfile" element={< ViewUserProfile />} />
          <Route path="/sendMessage" element={< SendMessage />} />
          <Route path="/viewMessages" element={< ViewMessages />} />
          <Route path="/viewMessage/:id" element={< ViewUserMessage />} />
          <Route path="/approvals" element={< ApprovalsPageAdmin />} />
          <Route path="/reviews" element={< ReviewsPageAdmin />} />
          </Routes>
          </main>
          <Footer />
      </Router>
      <ToastContainer />
    </>
);
}

export default App;
