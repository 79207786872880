import axios from "axios";
// import {toast} from "react-toastify";

// const API_URL = "/api/products/"; //dev
const API_URL = "https://us-central1-jinglebackend.cloudfunctions.net/api/api/products/"; //prod


//create product
const createProduct = async (productData, token) => {
  const config = {
    headers:{
      Authorization: `Bearer ${token}`
    }
  }
  try {

  const response = await axios.post(API_URL, productData, config);


    if (response.data) {

      // toast.success("Product added");

      // console.log(response.data.productid);




      return response.data
    }

  } catch (e) {
    // console.log(e);
    throw new Error(e.response.data.message || e.response.data)
  }
}

//get user products

const getProducts = async (token) => {
  const config = {
    headers:{
      Authorization: `Bearer ${token}`,
    }
  }

  // console.log(config);

  try {
  const response = await axios.get(API_URL, config);


    if (response.data) {

      // toast.success("Products loaded!")


      return response.data
    }

  } catch (e) {
    // console.log(e);
    throw new Error(e.response.data.message || e.message)
  }
}

//get ALL products

const searchAllProducts = async (token) => {
  const config = {
    headers:{
      Authorization: `Bearer ${token}`,
    }
  }

  // console.log(config);

  try {
  const response = await axios.get(API_URL + "all", config);


    if (response.data) {

      // toast.success("Products loaded!")


      return response.data
    }

  } catch (e) {
    // console.log(e);
    throw new Error(e.response.data.message || e.message)
  }
}

//View product
const viewProduct = async (productid, token) => {
  const config = {
    headers:{
      Authorization: `Bearer ${token}`,
    }
  }

  // console.log(config);

  try {
  const response = await axios.get(API_URL + "view/" + productid, config);


    if (response.data) {

      // toast.success("Product loaded!")
      // console.log(response.data);


      return response.data
    }

  } catch (e) {
    throw new Error(e.response.data.message || e.message)
  }
}

// Delete user product
const deleteProduct = async (productid, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  try {

    const response = await axios.delete(API_URL + "view/" + productid, config);

    if (response.data) {

      // console.log(response.data);

      return response.data
    }

  } catch (e) {
    throw new Error(e.response.data.message || e.message)

  }

}

// Update user product
const updateProduct = async ([productData,prodid], token) => {
  const config = {
    headers:{
      Authorization: `Bearer ${token}`
    }
  }
  try {

  const response = await axios.put(API_URL + "update/" + prodid, productData, config);


    if (response.data) {

      // toast.success("Product added");

      // console.log(response.data.productid);




      return response.data
    }

  } catch (e) {
    // console.log(productData);
    throw new Error(e.response.data.message || e.message)
  }
}

// SOLD user product
const soldProduct = async (productid, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }


  try {

    const response = await axios.get(API_URL + "sold/" + productid, config);

    // console.log(response);

    if (response.data) {

      // console.log(response.data);

      return await response.data
    }

  } catch (e) {
    throw new Error(e.response.data.message || e.message)

  }

}

// Get approvals page
const getApprovalsPage = async (token) => {
  const config = {
    headers:{
      Authorization: `Bearer ${token}`,
    }
  }

  try {
    const response = await axios.get(API_URL + "approvals", config);

    if (response.data) {
      return await response.data;
    }

  } catch (error) {
    throw new Error(error.response.data.message || error.message);
  }
}

// Get approvals page
const getReviewsPage = async (token) => {
  const config = {
    headers:{
      Authorization: `Bearer ${token}`,
    }
  }

  try {
    const response = await axios.get(API_URL + "reviews", config);

    if (response.data) {
      return await response.data;
    }

  } catch (error) {
    throw new Error(error.response.data.message || error.message);
  }
}

// Approve product
const approveProduct = async (productid, token) => {
  const config = {
    headers:{
      Authorization: `Bearer ${token}`,
    }
  }

  try {
    const response = await axios.put(API_URL + productid + "/approve", {}, config);


    if (response.data) {
      return await response.data
    }

  } catch (error) {
    throw new Error(error.response.data.message || error.message);
  }
}

// Reject product
const rejectProduct = async (productid, token) => {
  const config = {
    headers:{
      Authorization: `Bearer ${token}`,
    }
  }

  try {
    const response = await axios.put(API_URL + productid + "/reject", {}, config);

    if (response.data) {
      return await response.data;
    }

  } catch (error) {
    throw new Error(error.response.data.message || error.message);
  }
}



const productService = {
  createProduct,
  getProducts,
  searchAllProducts,
  viewProduct,
  deleteProduct,
  updateProduct,
  soldProduct,
  //
  getApprovalsPage,
  getReviewsPage,
  approveProduct,
  rejectProduct,
}

export default productService
