import {useState,useEffect} from "react";
import {useSelector,useDispatch} from "react-redux";
import {Link,useNavigate} from "react-router-dom";
import Spinner from "../components/Spinner";
import {FaBox,FaTimes,FaCameraRetro,FaExclamationTriangle,FaCheck } from "react-icons/fa";
import {toast} from "react-toastify";

import {createProduct,resetProduct} from "../features/products/productSlice";
import {reset, logout} from "../features/auth/authSlice";

const NAME_REGEX = /^[a-zA-Z]{3,23}$/;
const TEXT_REGEX = /^[a-zA-Z0-9\s,'-]{10,255}$/;
const PRICE_REGEX = /^([1-9]\d{0,2}(\.\d{1,2})?|0\.\d{1,2})$/;


function ProductForm(){

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {user} = useSelector((state => state.auth));
  const {isLoading,isError,isSuccess,message,prodid,isAdded} = useSelector((state => state.products));

  useEffect(()=>{

    if (isError) {

      toast.error(message);
      if (message.match(/(Token expired|Not authorized|no token)/g)) {

        dispatch(logout());
        navigate('/login', { replace: true });

      }
      dispatch(resetProduct())

      // throw new Error (message);
      // dispatch(reset())



    }

    if (!user) {
        dispatch(reset())
        navigate('/login', { replace: true });
    }


    if (isSuccess && prodid && isAdded) {

      // console.log(prodid);
      toast.success("Product sent to administrator for review.")
      //
      navigate('/dashboard')

    }

    dispatch(resetProduct());


  }, [user,isError,isSuccess,message,navigate,prodid,isAdded,dispatch])


  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [price, setPrice] = useState(0);



  const [validName, setValidName] = useState(false);
  const [validDescription, setValidDescription] = useState(false);
  const [validPrice,setValidPrice] = useState(false) // boolean to check if name validates or not

  useEffect(() => {
    setValidName(NAME_REGEX.test(name));
    setValidDescription(TEXT_REGEX.test(description));
    setValidPrice(PRICE_REGEX.test(price));

  }, [name, description,price])

  const productData = new FormData();

  productData.append('name', name);
  productData.append('description', description);
  productData.append('price', price);


  const onChange = (e) => {
    // productData((prevState) => ({
      //     ...prevState,
      //     [e.target.name]:e.target.value //key of whatever the target "name" is (input name prop)
      //
      //   }))

      if (e.target.name === "name") {
        setName(e.target.value)

      }else if (e.target.name === "description") {
        setDescription(e.target.value)
      }else if (e.target.name === "price") {
        setPrice(e.target.value)
      }
      // else if (e.target.name === "images"){
        //   setImages(e.target.files)
        //
        // }
  };

  const fileObj = [];
  const fileArray = [];
  let otherarray = [];

  const [imgsSrc, setImgsSrc] = useState([]);
  const [images, setImgs] = useState([]);


  const uploadMultipleFiles = (e) => {
    otherarray.push(...e.target.files)
    fileObj.push(e.target.files)
    for (let i = 0; i < fileObj[0].length; i++) {
      fileArray.push(URL.createObjectURL(fileObj[0][i]))
    }

    setImgsSrc((imgs) => [...imgs, fileArray]);
    setImgs((url) => [...url, otherarray]);

  }


  const onSubmit = (e) => {
    e.preventDefault();
    // console.log(productData);


    images.forEach((item) => {

      // console.log(item);

      for (const key of Object.keys(item)) {
        // console.log(item[key]);
        productData.append('images', item[key])
      }

    });


      dispatch(createProduct(productData));





  }


  const removeSelectedImage = (index) => {
    setImgsSrc((prevState) => {
      let items = [...prevState];
      // console.log(items);
      items.splice(index, 1);
      return items;
    });

    setImgs((prevState) => {
      let items = [...prevState];
      // console.log(items);
      items.splice(index, 1);
      return items;
    });
  };


  if (isLoading) {
    return <Spinner />
  }


              return (
                <>
                <section className="form">
                <section className="heading">
                <h5><FaBox />Create a new product</h5>
                </section>


                <small className="text-danger mt-2"><FaExclamationTriangle/> Remember to add a Zipcode to your <Link to="/viewUserProfile" className="text-primary">profile</Link> before creating a product.</small>
                <br/>
                <small className="text-danger mt-2"><FaExclamationTriangle/> Your product will undergo a review process by the site's admin before it is published on the website.</small>

                <form onSubmit={onSubmit} encType='multipart/form-data'>

                <div className="form-group">
                  <label htmlFor="name">Name
                  <FaCheck className={validName ? "valid" : "hide"} />
                  <FaTimes className={!validName && name ? "invalid" : "hide"} /></label>
                  <input
                  type="text"
                  className="form-control"
                  id="name"
                  name="name"
                  value={name}
                  placeholder="Enter a Product name"
                  onChange={onChange}
                  required
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="description">Description
                  <FaCheck className={validDescription ? "valid" : "hide"} />
                  <FaTimes className={!validDescription && description ? "invalid" : "hide"} /></label>
                  <textarea
                  className="form-control"
                  id="description"
                  name="description"
                  value={description}
                  placeholder="Enter a Description"
                  onChange={onChange}
                  required></textarea>
                </div>

                <div className="form-group">
                  <label htmlFor="price">Price
                  <FaCheck className={validPrice ? "valid" : "hide"} />
                  <FaTimes className={!validPrice && price ? "invalid" : "hide"} /></label>
                  <input
                  className="form-control"
                  type="number"
                  id="price"
                  name="price"
                  value={price}
                  placeholder="Enter a price"
                  onChange={onChange}
                  required/>
                </div>

                <div className="form-group">
                  <label className="" htmlFor="images"><FaCameraRetro className="mx-2" fill="#000"/>Upload Images</label>

                  <input type="file" className="form-control text-secondary" name="images" onChange={uploadMultipleFiles}/>
                  <small className="text-info">You can upload one image at a time with a maximum of 5 images.</small>

                  <div id="imageprevDIV" className={imgsSrc.length > 0 ? 'form-control imageprevDIV' : 'form-control hide'}>
                  {imgsSrc.map((link,index) => (
                    <div key={index} className="">
                    <img src={link} className="imageprevs" alt="..." />
                    <FaTimes  className="closebutton" onClick={() => removeSelectedImage(index)}/>
                    </div>
                  ))}
                  </div>



                </div>




                <div className="form-group">
                  <button className="btn btn-block btn-primary mt-3 mb-2 me-2" type="submit">Add product</button>
                  <button className='btn btn-warning mt-3 mb-2' onClick={() => navigate("/dashboard")}>
                  Back to Dashboard
                  </button>
                </div>


                </form>


                </section>
                <small className="figure-caption">Having Trouble? Click <Link to="#">here</Link> to learn how to create a perfect listing.</small>
                </>
              )
            }

            export default ProductForm;

            // {imgsSrc.length > 0 ? (
            //
            //   <button type="button" className="btn btn-secondary btn-block" onClick={uploadFiles}>Upload</button>
            // ) : null}
