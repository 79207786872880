import {useState,useEffect} from "react";
import {useSelector,useDispatch} from "react-redux";
import {useNavigate,useLocation} from "react-router-dom";
import Spinner from "../components/Spinner";
import {FaRocketchat} from "react-icons/fa";
import {toast} from "react-toastify";

import {startConversation,resetMessage} from "../features/messages/messageSlice";
import {reset, logout} from "../features/auth/authSlice";


function SendMessage(){

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const product = location.state

  const {user} = useSelector((state => state.auth));
  const {isLoading,isError,isSuccess,message,isMessageSent} = useSelector((state => state.messages));

  useEffect(()=>{

    if (isError) {

      toast.error(message);
      if (message.match(/(Token expired|Not authorized|no token)/g)) {

        dispatch(logout());
        navigate('/login', { replace: true });

      }
      dispatch(resetMessage())

      // throw new Error (message);
      // dispatch(reset())



    }

    if (!user) {
        dispatch(reset())
        navigate('/login', { replace: true });
    }

    if(!product){
      navigate(-1)
    }


    if (isSuccess && isMessageSent) {

      toast.success(message)
      navigate("/viewProduct/" + product.productid)
      //
    }

    dispatch(resetMessage());


  }, [user,product,isError,isSuccess,isMessageSent,message,navigate,dispatch])


  // const [formData, setFormData] = useState({
  //   forProduct:product.productid,
  //   touser:product.userid,
  //   toemail:product.useremail,
  //   messagebyuser:"",
  // });

  const [formData, setFormData] = useState({
  forProductId: product.productid,
  forProductName: product.productName,
  toUserId: product.userid,
  // toEmail: product.useremail, // Add this line
  toUsername: product.username,
  text: "",
});

  // const {forProduct,touser,toemail,messagebyuser} = formData;

  const { forProductId, forProductName, toUserId,toUsername,/*toEmail,*/ text } = formData;


  const onChange = (e) => {
     setFormData((prevState) => ({
       ...prevState,
       [e.target.name]: e.target.value,
     }));
   };

   const onSubmit = (e) => {
     e.preventDefault();
     dispatch(startConversation(formData));
   };
  // const onChange = (e) => {
  //   setFormData((prevState) => ({
  //         ...prevState,
  //         [e.target.name]:e.target.value //key of whatever the target "name" is (input name prop)
  //
  //       }))
  // };
  //
  //
  //
  // const onSubmit = (e) => {
  //   e.preventDefault();
  //
  //     dispatch(sendMessagetoUser(formData));
  //
  // }


  if (isLoading) {
    return <Spinner />
  }

  // <input id="toEmail" name="toEmail" value={toEmail} readOnly hidden />
  return (
    <>
      <section className="form">
        <h5><FaRocketchat />Send Message</h5>
        <form onSubmit={onSubmit} encType='multipart/form-data'>
        <p className="text-start font-italic">You are sending the message to <span className="text-primary">{toUsername}</span> for <span className="text-info">{forProductName}</span> </p>
          <div className="form-group">
            <label htmlFor="text">Message</label>
            <textarea
              className="form-control"
              id="text"
              name="text"
              value={text}
              placeholder="Enter message"
              onChange={onChange}
            />
          </div>
          <div className="form-group">
            <button className="btn btn-block btn-primary mx-1 mt-1" type="submit">Send</button>
            <button className='btn btn-warning mx-1 mt-1' onClick={() => navigate("/viewProduct/" + product.productid)}>
              Back to Product
            </button>
          </div>
        </form>
      </section>
    </>
  );
}

export default SendMessage;

// <button className="btn btn-block btn-primary" onClick={(e)=>{
//   e.preventDefault();
//   console.log()
// }}>CHECK</button>
