import {useState, useEffect, useRef} from "react";
import {useSelector, useDispatch} from "react-redux"; // get the state to dispatch the register function in the Authslice (useselector will select the state (is error is loading ..) useDispatch will dispatch the state register state )
import {useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import {FaSignInAlt, FaCheck, FaTimes, FaInfoCircle} from "react-icons/fa";
import {register, reset} from "../features/auth/authSlice"; //eported from the reducers
import Spinner from "../components/Spinner";
import ReCAPTCHA from "react-google-recaptcha";

//Validators Regexes

const USER_REGEX = /^[A-z][A-z0-9-_]{3,23}$/;
const EMAIL_REGEX = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;


function Register(){

  const usernameRef = useRef(); // allows us to set the focus on the component when the user loads
  const errorRef = useRef(); // if we get an error it's going to be annouce here



  const [formData, setFormData] = useState({ //this is for what the user enters in the form
    username:"",
    email:"",
    password:"",
    password2:"",
    recaptchaToken: null,
  });

  const {username,email,password,password2, recaptchaToken} = formData;

  const [validUsername,setvalidUsername] = useState(false) // boolean to check if name validates or not
  const [usernameFocus, setUsernameFocus] = useState(false)  //boolean to check focus on that input field or not

  const [validEmail,setValidEmail] = useState(false) // boolean to check if name validates or not
  const [emailFocus, setEmailFocus] = useState(false)  //boolean to check focus on that input field or not

  const [validPassword,setvalidPassword] = useState(false) // boolean to check if name validates or not
  const [passwordFocus, setPasswordFocus] = useState(false)  //boolean to check focus on that input field or not

  const [validMatchPassword,setvalidMatchPassword] = useState(false) // boolean to check if name validates or not
  const [matchPasswordFocus, setmatchPasswordFocus] = useState(false)  //boolean to check focus on that input field or not

  const [formErrorMsg, setFormErrorMsg] = useState("");
  const [successForm, setSuccessForm] = useState(false)

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {user, isLoading, isError, isSuccess, message} = useSelector((state) => state.auth);

  useEffect(()=>{

    //set focus when the component load to set the focus on username
    usernameRef.current.focus();

    if (isError) {

      toast.error(message);

    }

    if (isSuccess || user) {
      setSuccessForm(true);
      navigate("/login");
    }

    //reset the state
    dispatch(reset())

  }, [user, isError, isSuccess, message, navigate, dispatch])

  useEffect(() => {
    setvalidUsername(USER_REGEX.test(username));
    setValidEmail(EMAIL_REGEX.test(email));
    setvalidPassword(PWD_REGEX.test(password));
    setvalidMatchPassword(password === password2);
  }, [username,email,password,password2])


  useEffect(() => {
    setFormErrorMsg('');
  }, [username,email,password,password2,recaptchaToken])

  const onChange = (e) => {

    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]:e.target.value //key of whatever the target "name" is (input name prop)

    }))
  };

  const onCaptchaChange = (value) => {
setFormData((prevState) => ({
  ...prevState,
  recaptchaToken: value,
}));
};






  const onSubmit = (e) => {
    e.preventDefault();

    const v1 = USER_REGEX.test(username);
    const v2 = PWD_REGEX.test(password);
    if (!v1 || !v2 || !email || !recaptchaToken) {
      setFormErrorMsg("Invalid Entry");
      // toast.error("Invalid Entry");

    }else {
      const userData = {
        username,email,password,recaptchaToken
      }

      dispatch(register(userData))


    }
  };

  if (isSuccess) {
    // console.log(isSuccess);

  }


  if (isLoading) {

    return <Spinner />

  }

  return (

  <>
    {successForm ? (
        <section>
            <h3>Success!</h3>
        </section>
    ) : (

  <section className="form">

  <section className="heading">
  <h3>
  <FaSignInAlt/> Register
  </h3>
  </section>

  <p ref={errorRef} className={formErrorMsg ? "formErrorMsg" : "offscreen"} aria-live="assertive">{formErrorMsg}</p>
    <form onSubmit={onSubmit}>
      <div className="form-group">
      <label htmlFor="username">
      Username:
      <FaCheck className={validUsername ? "valid" : "hide"} />
      <FaTimes className={validUsername || !username ? "hide" : "invalid"} />
      </label>
        <input
        type="text"
        className="form-control"
        ref={usernameRef}
        id="username"
        name="username"
        autoComplete="off"
        value={username}
        required
        placeholder="Enter a username"
        onChange={onChange}

        aria-invalid={validUsername ? "false" : "true"}
        aria-describedby="uidnote"
        onFocus={() => setUsernameFocus(true)}
        onBlur={() => setUsernameFocus(false)}
        />

        <p id="uidnote" className={usernameFocus && username && !validUsername ? "instructions" : "offscreen"}>
        <FaInfoCircle />
        4 to 24 characters.<br />
        Must begin with a letter.<br />
        Letters, numbers, underscores, hyphens allowed.
        </p>

        <label htmlFor="email">
        Email:
        <FaCheck className={validEmail ? "valid" : "hide"} />
        <FaTimes className={validEmail || !email ? "hide" : "invalid"} />
        </label>
        <input
        type="email"
        className="form-control"
        autoComplete="off"
        id="email"
        name="email"
        value={email}
        required
        placeholder="Enter an email"
        onChange={onChange}

        aria-invalid={validEmail ? "false" : "true"}
        aria-describedby="emailnote"
        onFocus={() => setEmailFocus(true)}
        onBlur={() => setEmailFocus(false)}
        />

        <p id="email" className={emailFocus && email && !validEmail ? "instructions" : "offscreen"}>
        <FaInfoCircle />
        Must be a valid email.
        </p>


        <label htmlFor="password">
        Password:
        <FaCheck className={validPassword ? "valid" : "hide"} />
        <FaTimes className={validPassword || !password ? "hide" : "invalid"} />
        </label>
        <input
        type="password"
        className="form-control"
        id="password"
        name="password"
        value={password}
        required
        placeholder="Enter a password"
        onChange={onChange}

        aria-invalid={validPassword ? "false" : "true"}
        aria-describedby="pwdnote"
        onFocus={() => setPasswordFocus(true)}
        onBlur={() => setPasswordFocus(false)}
        />

        <p id="pwdnote" className={passwordFocus && !validPassword ? "instructions" : "offscreen"}>
        <FaInfoCircle />
        8 to 24 characters.<br />
        Must include uppercase and lowercase letters, a number and a special character.<br />
        Allowed special characters: <span aria-label="exclamation mark">!</span> <span aria-label="at symbol">@</span> <span aria-label="hashtag">#</span> <span aria-label="dollar sign">$</span> <span aria-label="percent">%</span>
        </p>


        <label htmlFor="password2">
        Confirm Password:
        <FaCheck className={validMatchPassword && password2 ? "valid" : "hide"} />
        <FaTimes className={validMatchPassword || !password2 ? "hide" : "invalid"} />
        </label>
        <input
        type="password"
        className="form-control mb-2"
        id="password2"
        name="password2"
        value={password2}
        required
        placeholder="Confirm password"
        onChange={onChange}

        aria-invalid={validMatchPassword ? "false" : "true"}
        aria-describedby="confirmpasswordnote"
        onFocus={() => setmatchPasswordFocus(true)}
        onBlur={() => setmatchPasswordFocus(false)}
        />
        <p id="confirmpasswordnote" className={matchPasswordFocus && !validMatchPassword ? "instructions" : "offscreen"}>
        <FaInfoCircle />
        Must match the first password input field.
        </p>

        </div>

        <div className="form-group">
        <ReCAPTCHA
        sitekey="6LcaTu8pAAAAAEh-mAhlPSerEc0Y9KoAWHqfCeGN"
        onChange={onCaptchaChange}
        />
          <button disabled={!validUsername || !validPassword || !validMatchPassword || !validEmail ? true : false} type="submit" className="btn btn-control btn-warning mt-2">Submit</button>
        </div>

    </form>
  </section>
)}
  </>
)

}

export default Register
