import {useState, useEffect} from "react";
import {useSelector, useDispatch} from "react-redux"; // get the state to dispatch the register function in the Authslice (useselector will select the state (is error is loading ..) useDispatch will dispatch the state register state )
import {useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import {sendverification, reset} from "../features/auth/authSlice"; //exported from the reducers
import Spinner from "../components/Spinner"

function Verify(){

    const [formData, setFormData] = useState({
      email:"",
    });

    const {email} = formData;

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const {isLoading, isError, isSuccess, message } = useSelector(
      (state) => state.auth
    );

    useEffect(() => {
      if (isError) {
        toast.error(message)
      }

      if (isSuccess) {

          navigate('/login')

      }

      dispatch(reset())
    }, [isError, isSuccess, message, navigate, dispatch])



    const onChange = (e) => {
      setFormData((prevState) => ({
        ...prevState,
        [e.target.name]:e.target.value //key of whatever the target "name" is (input name prop)

      }))
    };

    const onSubmit = (e) => {
      e.preventDefault();
      const userData = {
        email
      }
      dispatch(sendverification(userData))
    };

  if (isLoading) {
    return <Spinner />
  }

    return (
    <>

    <section className="heading">
      <h3>
        Verify your email
      </h3>
    </section>

    <section className="form">
      <form onSubmit={onSubmit}>
        <div className="form-group">
          <input
          type="email"
          className="form-control"
          id="email"
          name="email"
          value={email}
          placeholder="Enter your email"
          onChange={onChange}
          />

          </div>

          <div className="form-group">
            <button type="submit" className="btn btn-warning mt-2">Submit</button>
          </div>

      </form>
    </section>

    </>
  )
}

export default Verify
