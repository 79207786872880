import {Link} from "react-router-dom";

function PrivacyPolicy(){
  return(
    <>
    <section className="heading">
    <h1>Privacy Policy</h1>
    </section>
      <section>
        <p>This Privacy Policy describes how Jingle Market - https://jinglemarket.org ("we", "us", or "our") collects, uses, and discloses personal information when you use our application Jingle Market (the "App").</p>
        <p><strong>Intended Audience:</strong> This website/app is intended for children's use. Assistance from parents or legal guardians is appreciated. We reserve the right to control the content to ensure a safe and friendly environment for our users.</p>
        <h2>1. Information We Collect</h2>
        <p>We may collect the following types of personal information when you use our App:</p>
          <p><strong>Email Address:</strong> We may collect your email address when you register an account or subscribe to our newsletter.</p>
          <p><strong>Username:</strong> We may collect a username or display name that you choose when creating an account.</p>
          <p><strong>Product Information:</strong> We may collect information about products you view, purchase, or interact with within the App.</p>
          <p><strong>Location Information:</strong> We may collect your city and zipcode to provide location-based services.</p>
        <h2>2. How We Use Your Information</h2>
        <p>We may use the personal information we collect for the following purposes:</p>
          <p><strong>Providing Services:</strong> To provide and maintain our services, including account registration, product information, and customer support.</p>
          <p><strong>Personalization:</strong> To personalize your experience and deliver content and product recommendations tailored to your preferences.</p>
          <p><strong>Analytics:</strong> To analyze usage patterns, optimize the performance of the App, and understand how users interact with our services.</p>
          <p><strong>Communications:</strong> To communicate with you about your account, products, and updates related to our services.</p>
        <h2>3. Information Sharing:</h2>
        <p>We do not share your personal information with third parties, except as described in this Privacy Policy.</p>
        <h2>4. Data Retention</h2>
        <p>We will retain your personal information for as long as necessary to fulfill the purposes outlined in this Privacy Policy, unless a longer retention period is required or permitted by law.</p>
        <h2>5. User-Posted Products</h2>
        <p>Products posted by users of Jingle Market will be subject to review before posting. This review process is in place to prevent the posting of inappropriate content, such as offensive or explicit images, and to maintain a safe and respectful community environment.</p>
        <h2>6. Messaging System</h2>
        <p>The messaging system in Jingle Market allows direct communication between users. Messages exchanged through the messaging system are unmoderated, but we implement filters to prevent the transmission of profanity and other inappropriate content.</p>
        <h2>7. Your Choices</h2>
        <p>You may choose not to provide certain personal information, but this may limit your ability to use certain features of Jingle Market.</p>
        <h2>8. Security</h2>
        <p>We implement appropriate technical and organizational measures to protect the security and confidentiality of your personal information.</p>
        <h2>9. Updates to this Privacy Policy</h2>
        <p>We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.</p>
        <h2>10. Contact Us</h2>
        <p>If you have any questions or concerns about this Privacy Policy or our practices regarding your personal information, please use <Link className="text-primary" to="/contact">our contact form</Link> or send us an email at [info@jinglemarket.org].</p>
      </section>
    </>
  )
}

export default PrivacyPolicy
