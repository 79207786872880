// import {Link} from "react-router-dom";
import {toast} from "react-toastify";
import axios from "axios";
import Cookies from 'js-cookie'; // Import js-cookie



// const API_URL = "/api/users/"; //dev

const API_URL = "https://us-central1-jinglebackend.cloudfunctions.net/api/api/users/"; //prod


  // const navigate = useNavigate();

//register user
const register = async (userData) =>{
  const response = await axios.post(API_URL + "register", userData);

  try {

    if (response.data) {



      toast.success("User registered, please check your email to verify your account")

      // return response.data

    }

  } catch (e) {

    throw new Error (e)

  }


  // console.log(response.data);
  //
  // if (response.data.isverified === false) {
    //
    //   throw new Error("Verify your email before logging in");
    // }
    // try {
      //
      //   localStorage.setItem("user", JSON.stringify(response.data));
      //
      //   return response.data
      // } catch (e) {
        //
        //   throw new Error (e)
        //
        // }

        // }



      }

//login user
const login = async (userData) =>{
  const response = await axios.post(API_URL + "login", userData);

  if (response.data) {

    if (response.data.isverified === false) {

      throw new Error("Verify your email before logging in");
    }

    try {

      // localStorage.setItem("user", JSON.stringify(response.data))
      // Cookies.set("user", JSON.stringify(response.data)); // Set cookie here
      Cookies.set("user", JSON.stringify(response.data), {
        sameSite: 'None',
        secure: true
      });

      return response.data

    } catch (e) {

      throw new Error (e)

    }





  }
}

// Logout user
const logout = () => {
  // localStorage.removeItem('user');
  Cookies.remove('user'); // Remove cookie here

}

//sendverification user
const sendverification = async (userData) =>{

  const response = await axios.post(API_URL + "sendverification", userData);

try {

  if (response.data) {

      toast.success(response.data); //message from server to say email sent

  }
} catch (e) {
  console.error(e)

}


}

//sendverification user
const sendresetpass = async (userData) =>{

  const response = await axios.post(API_URL + "sendresetpassword", userData);

try {

  if (response.data) {

      toast.success(response.data); //message from server to say email sent

  }
} catch (e) {
  console.error(e)

}


}

// View user Profile
const viewUserProfile = async () => {

  // Get the user object from the cookie
  const user = JSON.parse(Cookies.get('user'));

  // Extract the token from the user object
  const token = user && user.token;

  // console.log(token);

  const config = {
    headers:{
      Authorization: `Bearer ${token}`,
    }
  }


  try {
  const response = await axios.get(API_URL + "viewMe", config);


    if (response.data) {

      // toast.success("Products loaded!")

      // console.log(response.data);

      return response.data
    }

  } catch (e) {
    // console.log(e);
    throw new Error(e.response.data.message || e.message)
  }
}

// update user Profile
const updateUserProfile = async (userData) => {

  // Get the user object from the cookie
  const user = JSON.parse(Cookies.get('user'));

  // Extract the token from the user object
  const token = user && user.token;

  const config = {
    headers:{
      Authorization: `Bearer ${token}`,
    }
  }


  try {
  const response = await axios.put(API_URL + "viewMe", userData, config);


    if (response.data) {

      // toast.success("Products loaded!")

      // console.log(response.data);

      return response.data
    }

  } catch (e) {
    // console.log(e);
    throw new Error(e.response.data.message || e.message)
  }
}

//contacts from user
const contact = async (formdata) =>{
  const response = await axios.post(API_URL + "contact", formdata);

  try {

    if (response.data) {


      return response.data

      // toast.success("Contact sent!")

      // return response.data

    }

  } catch (e) {

    throw new Error (e)

  }



}

const authService = {
  register,
  login,
  logout,
  contact,
  sendverification,
  viewUserProfile,
  updateUserProfile,
  sendresetpass
}

export default authService
