import {FaFacebook,FaLinkedin,FaTwitter,FaInstagram} from "react-icons/fa";
import {Link} from "react-router-dom";

function Footer() {

  return(
    <>
    <footer className="">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#0DCAF0" fillOpacity="1" d="M0,288L48,293.3C96,299,192,309,288,314.7C384,320,480,320,576,309.3C672,299,768,277,864,266.7C960,256,1056,256,1152,266.7C1248,277,1344,299,1392,309.3L1440,320L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"></path></svg>
              <section className="footer bg-info">

                <section className="footerlinks">
                <Link className="nav-link" to="/privacypolicy">Privacy Policy</Link>
                <Link className="nav-link" to="#">©2023</Link>
                </section>

                <section className="footersocial">
                  <Link className="nav-link" to="#"><FaFacebook size="40px" /></Link>
                  <Link className="nav-link" to="#"><FaTwitter size="40px" /></Link>
                  <Link className="nav-link" to="#"><FaInstagram size="40px" /></Link>
                  <Link className="nav-link" to="#"><FaLinkedin size="40px"/></Link>
                </section>

          </section>

    </footer>
    </>
  )
}

export default Footer

// <footer className="page-footer">
//   <nav className="navbar fixed-bottom ">
//
//     <div className="container-fluid">
//       <ul className="navbar-nav">
        // <li className="nav-item"><Link className="nav-link" to="#">Privacy Policy</Link></li>
        // <li className="nav-item"><Link className="nav-link" to="#">Legal Notice</Link></li>
        // <li className="nav-item"><Link className="nav-link" to="#">Contact</Link></li>
//       </ul>
//
//
//     </div>
//
//     <div className="fixed-bottom text-center">
//       <div className="container-fluid">
        // <Link className="nav-brand" to="/"><img src={logo} className="logo" alt="logo" /></Link>
        // <p className="navbar-text">Jingle© 2022</p>
//       </div>
//     </div>
//
//   </nav>
// </footer>
