import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import messageService from "./messageService";

const initialState = {
  conversations: [],
  conversation: null,
  isError: false,
  isSuccess: false,
  isLoading: false,
  isDeleted: false,
  isMessageSent: false,
  isResponse: false,
};

// Get all conversations of a user
export const getConversations = createAsyncThunk("messages/getConversations", async (_, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token;
    return await messageService.getConversations(token);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

// Start a new conversation
export const startConversation = createAsyncThunk("messages/startConversation", async (conversationData, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token;
    return await messageService.startConversation(conversationData, token);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

// Get a conversation by ID
export const getConversation = createAsyncThunk("messages/getConversation", async (conversationId, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token;
    return await messageService.getConversation(conversationId, token);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

// Respond to a conversation
export const respondToConversation = createAsyncThunk("messages/respondToConversation", async ([conversationId, messageData], thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token;
    return await messageService.respondToConversation([conversationId, messageData], token);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const messageSlice = createSlice({
  name: "messages",
  initialState,
  reducers: {
    resetMessage: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getConversations.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getConversations.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.conversations = action.payload;
      })
      .addCase(getConversations.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(startConversation.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(startConversation.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isMessageSent = true;
        state.message = action.payload;
      })
      .addCase(startConversation.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isMessageSent = false;
        state.message = action.payload;
      })
      .addCase(getConversation.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getConversation.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.conversation = action.payload;
      })
      .addCase(getConversation.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(respondToConversation.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(respondToConversation.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isResponse = true;
        state.message = action.payload;
      })
      .addCase(respondToConversation.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export const { resetMessage } = messageSlice.actions;

export default messageSlice.reducer;
