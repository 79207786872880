import {useEffect,useState} from "react";
import {useSelector,useDispatch} from "react-redux";
import {Link,useNavigate} from "react-router-dom";
import {FaSearch} from "react-icons/fa";
import Spinner from "../components/Spinner";
import {toast} from "react-toastify";


//filter

// import logo from "../logos/logo2_192.png";


import {searchAllProducts, resetProduct} from "../features/products/productSlice";
import {reset,logout} from "../features/auth/authSlice";


function ProductSearch(){

  const navigate = useNavigate();
  const dispatch = useDispatch();


  const {user} = useSelector((state => state.auth));

  const {products,isLoading,isSuccess, isError, message} = useSelector((state => state.products));


  useEffect(()=>{

    if (isError) {

      toast.error(message);
      // console.log(message);
      if (message.match(/(Token expired|Not authorized|no token)/g)) {

        dispatch(logout());
        navigate('/login', { replace: true });

      }
      // throw new Error(message);
      // dispatch(reset())
      dispatch(resetProduct());


    }

    if (!user) {
        dispatch(reset())
        navigate('/login', { replace: true });
    }







    // if (isSuccess) {
    //
    //   toast.success("Products loaded!")
    //
    //
    //   // navigate("/dashboard")
    //     // dispatch(reset())
    // }
    dispatch(searchAllProducts())

    // return () => {
    // }

        // console.log(products);

  }, [user,navigate,isSuccess,isError,message,dispatch])


  // for search bar

  const [query, setQuery] = useState("")




    if (isLoading) {
      return <Spinner />
    }



    //adding here to test
    // <ProductSearch key={product.productid} product={product} />

    return (
      <>



      <section>

      <div className="searchbar">

        <div className="input-group mb-3">
          <div className="input-group-prepend">
            <button className="btn btn-outline-secondary" type="button"><FaSearch color="" fontSize="1.5rem"/></button>
          </div>
          <input placeholder="Enter Product Name, State, City" className="form-control" onChange={event => setQuery(event.target.value)}/>
        </div>

      </div>

      {products.length > 0 ? (

        <div className="row row-cols-1 row-cols-md-2 g-4">

        {
          products.filter(product => {
            if (query === '' || !query) {
              return product.sold === false;
            } else if (product.productName.toLowerCase().includes(query.toLowerCase()) || product.productCITY.toLowerCase().includes(query.toLowerCase()) || product.productSTATE.toLowerCase().includes(query.toLowerCase())) {
              return product.sold === false;
            } else if (typeof query === 'number' && product.productZIP === Number(query)){
              return product.sold === false;
            }
          }).map((product, index) => (
            <div className="col" key={index} product={product}>
              <div className="card mx-auto text-center shadow">
              <div className="card-header">
              <h5 className="card-title h3">{product.productName}</h5>
              </div>
              <div className="card-img-top">
              <img src={product.productImages[0]} className="rounded bg-light productimg " alt="..."/>
              </div>
                  <div className="card-body">
                    <p className="card-text text-center">${product.productPrice}</p>
                  <div className="card-footer">
                  <small className="text-muted">{new Date(product.productDate).toLocaleString('en-US')}</small>
                  </div>
                </div>
                <Link className="btn btn-info stretched-link text-white" to={"/viewProduct/" + product.productid}>Display this Product</Link>
              </div>
            </div>
          ))
        }

        </div>




      ) : (<h3>There are no products to display.</h3>)
    }
    </section>
    </>
  )
}

export default ProductSearch


// {products.map((product)=>(
//
//   <div className="col" key={product.productid} product={product}>
//     <div className="card mx-auto text-center">
//     <div className="card-header">
//     <h5 className="card-title">{product.productName}</h5>
//     </div>
//     <div className="">
//     <img src={product.productImages[0]} className="card-img-top rounded bg-light productimg " alt="..."/>
//     </div>
//         <div className="card-body">
//           <p className="card-text">${product.productPrice}</p>
//         <div className="card-footer">
//         <small className="text-muted">{new Date(product.productDate).toLocaleString('en-US')}</small>
//         </div>
//       </div>
//       <Link className="btn btn-secondary" to={"/viewProduct/" + product.productid}>Display this Product</Link>
//     </div>
//   </div>
// ))}
