import {useState, useEffect} from "react";
import {FaUser} from "react-icons/fa";
import {useSelector, useDispatch} from "react-redux"; // get the state to dispatch the register function in the Authslice (useselector will select the state (is error is loading ..) useDispatch will dispatch the state register state )
import {Link,useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import {login, reset} from "../features/auth/authSlice"; //eported from the reducers
import Spinner from "../components/Spinner";
import ReCAPTCHA from "react-google-recaptcha";


function Login(){

    const [formData, setFormData] = useState({
      usernameOrEmail: "",
      password:"",
      recaptchaToken: null,
    });

    const {usernameOrEmail,password,recaptchaToken} = formData;

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { user, isLoading, isError, isSuccess, message } = useSelector(
      (state) => state.auth
    );

    useEffect(() => {
      if (isError) {
        toast.error(message)
      }

      if (isSuccess || user) {
        // console.log(user);
        // if (user.isverified == false) {
        //
        // }
        //
        // if (user.isverified == true) {

          navigate('/dashboard')
        // }

      }

      dispatch(reset())
    }, [user, isError, isSuccess, message, navigate, dispatch])



    const onChange = (e) => {
      setFormData((prevState) => ({
        ...prevState,
        [e.target.name]:e.target.value //key of whatever the target "name" is (input name prop)

      }))
    };

    const onCaptchaChange = (value) => {
  setFormData((prevState) => ({
    ...prevState,
    recaptchaToken: value,
  }));
};

const onSubmit = (e) => {
  e.preventDefault();

  let userData;
  if (usernameOrEmail.includes('@')) {
    userData = {
      email: usernameOrEmail,
      password,
      recaptchaToken,
    }
  } else {
    userData = {
      username: usernameOrEmail,
      password,
      recaptchaToken,
    }
  }

  dispatch(login(userData))
};

  if (isLoading) {
    return <Spinner />
  }

  return (
    <>
      <section className="heading">
        <h3>
          <FaUser/> Login
        </h3>
      </section>

      <section className="form">
        <form onSubmit={onSubmit}>
          <div className="form-group">
            <label htmlFor="usernameOrEmail">
              Username or Email:
            </label>
            <input
              type="text"
              className="form-control"
              id="usernameOrEmail"
              name="usernameOrEmail"
              value={usernameOrEmail}
              placeholder="Enter a username or email"
              onChange={onChange}
            />

            <label htmlFor="password">
              Password:
            </label>
            <input
              type="password"
              className="form-control mb-2"
              id="password"
              name="password"
              value={password}
              placeholder="Enter a password"
              onChange={onChange}
            />
          </div>

          <div className="form-group">
            <ReCAPTCHA
              sitekey="6LcaTu8pAAAAAEh-mAhlPSerEc0Y9KoAWHqfCeGN"
              onChange={onCaptchaChange}
            />
            <button type="submit" className="btn btn-warning mt-2">Submit</button>
          </div>
        </form>
      </section>

      <section className="resetlinks mt-4">
        <div className="verify">
          <Link className="nav-link" to="/verify">Verify Account</Link>
        </div>
        <div className="resetpass">
          <Link className="nav-link" to="/resetpassword">Forgot Password?</Link>
        </div>
      </section>
    </>
  )
}

export default Login
