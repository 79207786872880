import {useEffect} from "react";
import {useSelector,useDispatch} from "react-redux";
import {Link,useNavigate,useParams} from "react-router-dom";
import Spinner from "../components/Spinner";
import {toast} from "react-toastify";
import { Carousel } from 'react-responsive-carousel';
import {FaEye, FaCheckCircle,FaDollarSign,FaArrowLeft} from "react-icons/fa";


import {viewProduct,soldProduct, resetProduct,approveProduct, rejectProduct} from "../features/products/productSlice";
import {reset,logout} from "../features/auth/authSlice";


function ProductView(){

  const navigate = useNavigate();
  const dispatch = useDispatch();


  const {user} = useSelector((state => state.auth));

  const {products,isLoading,isSuccess, isError,isUpdatedSoldRelist, isReviewed, message} = useSelector((state => state.products));

  let { id } = useParams();

  //ADD SEARCH BAR TO FILTER PRODUCTS


  useEffect(()=>{


    if (isError) {

      toast.error(message);

      if (message.match(/(Token expired|Not authorized|no token)/g)) {

        dispatch(logout());
        navigate('/login', { replace: true });

      }

      // navigate(-1)
      dispatch(resetProduct())

    }


    if (isSuccess && isUpdatedSoldRelist) {
//product found and loaded
      toast.success(message);
      dispatch(resetProduct())

    }

    if (isSuccess && isReviewed) {
//product found and loaded
      toast.success(message);
      dispatch(resetProduct())

    }



    if (!user) {
        dispatch(reset())
        navigate('/login', { replace: true });
    }


    dispatch(viewProduct(id));


// dispatch(reset())
//         // console.log(products);

}, [user,id,navigate,isSuccess,isError,isUpdatedSoldRelist,isReviewed,message,dispatch])



    if (isLoading) {
      return <Spinner />
    }


    const handleSold = (thisid) => {

      // console.log(thisid);

      dispatch(soldProduct(thisid));
      // dispatch(reset())
    }

    const handleApprove = (thisid) => {
      dispatch(approveProduct(products.productid));
    }

    const handleReject = (thisid) => {
      dispatch(rejectProduct(products.productid));
    }


    return (
      <>

      <section className="container">
        <div className="row justify-content-center align-items-center">
          <div className="col" key={products.productid}>
            <div className= {products.sold === true ? ("card mx-auto text-center opacity-50"):(" card mx-auto text-center opacity-100")}>
              <div className="card-header">
              <div className="viewsSection">
              {
                products.sold === true ? (
                  <p className="card-text text-start"><FaCheckCircle/> Sold on {products.soldDate}</p>
                ):(
                  <p className="card-text text-start text-success"><FaDollarSign/> Listed</p>
                )
              }
              <p className="card-text text-end"><FaEye/><em> {products.countOfViews} views</em></p>
              </div>
              <h5 className="card-title text-center h3">{products.productName}</h5>
              </div>
              <div className="card-body">
              <Carousel showThumbs={true} dynamicHeight={true}>
                  {products.productImages ? (
                    products.productImages.map((imgSrc, index) => (
                        <img src={imgSrc} key={index} className="productimgview" alt="..."/>
                      ))


                  ):(
                    <p>no images</p>
                  )}
              </Carousel>


                  <div className="card-text">
                  <p>{products.productDescription}</p>

                  <p className="text-center">${products.productPrice}</p>
                  </div>

                  <div className="card-footer text-center">
                    <div className="datesection">
                    <small className="text-muted text-center">{new Date(products.productDate).toLocaleString('en-US')}</small>
                    </div>
                    <div className="contactsection">
                    <p className="card-text text-muted" id="emailContact">Contact:{`${products.username} - ${products.useremail}`}</p>
                    <p className="card-text text-muted">{`${products.productZIP} - ${products.productCITY} - ${products.productSTATE}`}</p>
                    </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
    </section>

    <div className="productactions">
    {user.id === products.userid ? (

        <>
        {products.sold === false ? (


          <button className="btn btn-success mx-1" onClick={() => handleSold(products.productid)}><FaCheckCircle/> Mark as sold</button>

        ):(
          <button className="btn btn-success mx-1" onClick={() => handleSold(products.productid)}><FaDollarSign/> Relist</button>

        )}

        <Link className={products.sold === true ? ("btn btn-light disabled mx-1"):("btn btn-info mx-1")} to={"/updateProduct/" + products.productid}>Update</Link>
        </>
      ) : (
        <>
          <Link className="btn btn-primary mx-1" to="/sendMessage/" state={products}>Send Message</Link>
          {user.role === 'admin' && (
            <div className= "mt-2">
            <button className="btn btn-success mx-1 " onClick={() => handleApprove(products.productid)}>Approve</button>
            <button className="btn btn-danger mx-1" onClick={() => handleReject(products.productid)}>Reject</button>
            </div>
          )}
        </>
    )}
    <button className='btn btn-warning mx-1' onClick={() => navigate(-1)}>
    <FaArrowLeft />
    </button>

    </div>
    </>
  )

}

export default ProductView
