import {Link} from "react-router-dom";

function Home(){
  return(
    <>

    <div className="wrapper">

      <section>

      <div className="welcomeText">
      <h3>Welcome to Jingle!</h3>
      <p>Thank you for choosing Jingle. Please <Link className="nav-link" to='/login'>Login</Link> or <Link className="nav-link" to='/register'>Register</Link> to begin your journey.</p>
      </div>

      </section>

    </div>

    </>

  )
}

export default Home
