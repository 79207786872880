import {useEffect} from "react";
import {Link,useNavigate} from "react-router-dom";
import {useSelector, useDispatch} from "react-redux"; // get the state to dispatch the register function in the Authslice (useselector will select the state (is error is loading ..) useDispatch will dispatch the state register state )
import Spinner from "../components/Spinner";
import {toast} from "react-toastify";

import {getApprovalsPage,resetProduct} from "../features/products/productSlice";
import {getConversations,resetMessage} from "../features/messages/messageSlice";

import {reset,logout} from "../features/auth/authSlice";
//icons
import {FaAddressCard, FaBoxOpen, FaPlusSquare ,FaRegEnvelope} from "react-icons/fa";
import { FaChessKnight,FaCheckToSlot  } from "react-icons/fa6";


function Dashboard(){

  let navigate = useNavigate();

  const dispatch = useDispatch();

  const {user,isLoading, isError, message} = useSelector((state => state.auth));


  // console.log(useSelector((state => state.auth)));

  const {conversations} = useSelector((state => state.messages));
  const {products} = useSelector((state) =>state.products)

  useEffect(()=>{

    if (isError) {

      // console.log(message);
      toast.error(message)

      if (message.match(/(Token expired|Not authorized|no token)/g)) {

        dispatch(logout());
        navigate('/login', { replace: true });

      }

    }

    if (!user) {
        dispatch(reset())
        navigate('/login', { replace: true });
    }

    // dispatch(getProducts())

    // do something when the component unmounts
    // return () =>{
    // }
    if (user && user.role === "admin") {
      dispatch(getApprovalsPage());

    }
      dispatch(getConversations())

  }, [user,navigate,isError,message,dispatch])

  if (isLoading) {
    return <Spinner />
  }

  return(
    <>
      <section className="heading mb-2">
        <h3>Hi <span>{user && user.username}!</span></h3>
      </section>

      <section className="dashboardcontainer">

      <div className="card mx-auto text-center shadow" style={{width: '18rem'}}>
      <FaPlusSquare className="card-img-top" color="#00cba9" fontSize="10rem"/>
      <div className="card-body">
      <Link to="/addProduct" className="btn btn-info stretched-link text-white">Add a Product</Link>
      </div>
      </div>

      <div className="card mx-auto text-center shadow" style={{width: '18rem'}}>
      <FaBoxOpen className="card-img-top" color="#00cba9" fontSize="10rem"/>
      <div className="card-body">
      <Link to="/viewUserProducts" className="btn btn-info stretched-link text-white">View your Products</Link>
      </div>
      </div>

      <div className="card mx-auto text-center shadow" style={{width: '18rem'}}>
      <FaAddressCard className="card-img-top" color="#00cba9" fontSize="10rem"/>
      <div className="card-body">
      <Link to="/viewUserProfile" className="btn btn-info stretched-link text-white">View/Edit your profile</Link>
      </div>
      </div>

      <div className="card mx-auto text-center shadow" style={{width: '18rem'}}>
      <FaRegEnvelope className="card-img-top" color="#00cba9" fontSize="10rem"/>
      <div className="card-body">
      <Link to="/viewMessages" className="btn btn-info stretched-link text-white">View your messages</Link>
      {conversations && conversations.map((conversation) => {
        let messages = JSON.parse(conversation.messages);
        // Check if messages is an array
        if (!Array.isArray(messages)) {
          messages = [messages];
        }
        return (
          <div key={conversation.messageid}>
            {messages.some(message => message.viewed === false) && (
              <span className="notification-icon badge bg-danger">NEW</span>
            )}
          </div>
        );
      })}
      </div>
      </div>

      {user && user.role === 'admin' && (
        <>
        <div className="card mx-auto text-center shadow" style={{width: '18rem'}}>
        <FaChessKnight className="card-img-top" color="#cb0022" fontSize="10rem"/>
        <div className="card-body">
        <Link to="/approvals" className="btn btn-info stretched-link text-white">Approvals</Link>
        {products && products.map((product) => {
          return (
            <div key={product.productid}>
              {product.isReviewed === false && (
                <span className="notification-icon badge bg-danger">NEW</span>
              )}
            </div>
          );
        })}
        </div>
        </div>

        <div className="card mx-auto text-center shadow" style={{width: '18rem'}}>
        <FaCheckToSlot className="card-img-top" color="#cb0022" fontSize="10rem"/>
        <div className="card-body">
        <Link to="/reviews" className="btn btn-info stretched-link text-white">Reviewed Products</Link>
        </div>
        </div>

        </>
      )}

      </section>



    </>

  )
}

export default Dashboard
