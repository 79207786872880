import { configureStore } from '@reduxjs/toolkit';
import authReducer from "../features/auth/authSlice";
import productReducer from "../features/products/productSlice";
import messageReducer from "../features/messages/messageSlice";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    products: productReducer,
    messages: messageReducer
  },
});
