//slice REDUX term that manages users

import{createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import authService from "./authService";
import Cookies from 'js-cookie'; // Import js-cookie


// //this is where we save the token from backend
// const user = JSON.parse(localStorage.getItem("user"));

// const user = JSON.parse(Cookies.get("user")); // Get user data from cookies
let user;
const userCookie = Cookies.get("user"); // Get user data from cookies

if (userCookie && userCookie !== "") {
  user = JSON.parse(userCookie);
} else {
  user = null;
}



const initialState = {
  user: user ? user : null,
  isError:false,
  isSuccess:false,
  isLoading:false,
  isUpdated:false,
  message:"",
  userProfile:[]
};

//Register user
export const register = createAsyncThunk("auth/register", async (user, thunkAPI)=>{
  try {

    return await authService.register(user) //checks state.user

  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message); //checks state.isError -> throws message
  }
});

//Login user
export const login = createAsyncThunk("auth/login", async (user, thunkAPI)=>{
  try {

    return await authService.login(user) //checks state.user

  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message); //checks state.isError -> throws message
  }
});

//Logout user
export const logout = createAsyncThunk("auth/logout", async ()=>{
  await authService.logout()
})

//Sendverification user
export const sendverification = createAsyncThunk("auth/sendverification", async (email,thunkAPI)=>{
  try {

    return await authService.sendverification(email) //checks state.user

  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message); //checks state.isError -> throws message
  }
});

//Sendverification user
export const sendresetpass = createAsyncThunk("auth/sendresetpassword", async (email,thunkAPI)=>{
  try {

    return await authService.sendresetpass(email) //checks state.user

  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message); //checks state.isError -> throws message
  }
});

// view user profile
export const viewUserProfile = createAsyncThunk("auth/viewUserProfile", async(_,thunkAPI) =>{

  try {
    // const token = thunkAPI.getState().auth.user.token
    const user = JSON.parse(Cookies.get('user')); // Get user data from cookies
    const token = user && user.token; // Extract the token from the user object

    // console.log(token);
    return await authService.viewUserProfile(token)

  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message); //checks state.isError -> throws message


  }

})

//update User Profile
export const updateUserProfile = createAsyncThunk("auth/updateUserProfile", async(userData,thunkAPI) =>{

  try {
    // const token = thunkAPI.getState().auth.user.token
    const user = JSON.parse(Cookies.get('user')); // Get user data from cookies
    const token = user && user.token; // Extract the token from the user object

    // console.log(token);
    return await authService.updateUserProfile(userData,token)

  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message); //checks state.isError -> throws message


  }

})

//contacts from user
export const contact = createAsyncThunk("auth/contact", async (contactformdata, thunkAPI)=>{
  try {

    return await authService.contact(contactformdata) //checks state.user

  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message); //checks state.isError -> throws message
  }
});


export const authSlice = createSlice({
  name:"auth",
  initialState,
  reducers:{
    reset: (state)=>{
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = "";
      // state.userProfile = [];
    },
    resetProfile:(state)=>{
      state.isUpdated = false;
      state.userProfile = [];

    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(register.pending, (state)=>{
        state.isLoading = true;
      })
      .addCase(register.fulfilled, (state, action)=>{
        state.isLoading = false;
        state.isSuccess = true;
        state.user = action.payload
      })
      .addCase(register.rejected, (state, action)=>{
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.user = null;
      })
      .addCase(login.pending, (state)=>{
        state.isLoading = true;
      })
      .addCase(login.fulfilled, (state, action)=>{
        state.isLoading = false;
        state.isSuccess = true;
        state.user = action.payload;
      })
      .addCase(login.rejected, (state, action)=>{
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.user = null;
      })
      .addCase(logout.fulfilled, (state) => {
        state.user = null;
        state.userProfile = [];
      })
      .addCase(sendverification.pending, (state)=>{
        state.isLoading = true;
      })
      .addCase(sendverification.fulfilled, (state, action)=>{
        state.isLoading = false;
        state.isSuccess = true;
        state.message = action.payload;
        // state.user = action.payload
      })
      .addCase(sendverification.rejected, (state, action)=>{
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        // state.user = null;
      })
      .addCase(sendresetpass.pending, (state)=>{
        state.isLoading = true;
      })
      .addCase(sendresetpass.fulfilled, (state, action)=>{
        state.isLoading = false;
        state.isSuccess = true;
        state.message = action.payload;
        // state.user = action.payload
      })
      .addCase(sendresetpass.rejected, (state, action)=>{
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        // state.user = null;
      })
      .addCase(viewUserProfile.pending, (state)=>{
        state.isLoading = true;
      })
      .addCase(viewUserProfile.fulfilled, (state, action /*action will be pushed to the product array */)=>{
        state.isLoading = false
        state.isSuccess = true
        state.isUpdated = false
        state.userProfile = action.payload
        // state.prodid = action.payload;
        // state.products.filter(
        //   (product) => product.productid !== Number(action.payload.id)
        // )
      })
      .addCase(viewUserProfile.rejected, (state, action /*action is the message*/ )=>{
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      })
      .addCase(updateUserProfile.pending, (state)=>{
        state.isLoading = true;
      })
      .addCase(updateUserProfile.fulfilled, (state, action /*action will be pushed to the product array */)=>{
        state.isLoading = false
        state.isSuccess = true
        state.isUpdated = true
        state.userProfile = action.payload
        // state.prodid = action.payload;
        // state.products.filter(
        //   (product) => product.productid !== Number(action.payload.id)
        // )
      })
      .addCase(updateUserProfile.rejected, (state, action /*action is the message*/ )=>{
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      })
      .addCase(contact.pending, (state)=>{
        state.isLoading = true;
      })
      .addCase(contact.fulfilled, (state, action)=>{
        state.isLoading = false;
        state.isSuccess = true;
        state.message = action.payload
      })
      .addCase(contact.rejected, (state, action)=>{
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
  },
});

export const {reset,resetProfile} = authSlice.actions;
export default authSlice.reducer;
