import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import productService from "./productService"

const initialState = {
  products:[],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
  prodid:"",
  isUpdated:false,
  isDeleted:false,
  isUpdatedSoldRelist:false,
  isAdded:false,
  isReviewed:false
  // viewprod:[]
}

//create new product
export const createProduct = createAsyncThunk("products/create", async(productData,thunkAPI)=>{
  try {
    const token = thunkAPI.getState().auth.user.token
    return await productService.createProduct(productData,token);

  } catch (error) {

    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message); //checks state.isError -> throws message

  }

})

//get user products
export const getProducts = createAsyncThunk("products/getUserProducts", async(_,thunkAPI) =>{

  try {
    const token = thunkAPI.getState().auth.user.token

    // console.log(token);
    return await productService.getProducts(token)

  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message); //checks state.isError -> throws message


  }

})

//search ALL products
export const searchAllProducts = createAsyncThunk("products/getAll", async(_,thunkAPI) =>{

  try {
    const token = thunkAPI.getState().auth.user.token

    // console.log(token);
    return await productService.searchAllProducts(token)

  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message); //checks state.isError -> throws message


  }

})

//View product
export const viewProduct = createAsyncThunk("products/view", async(prodid,thunkAPI)=>{
  try {
    const token = thunkAPI.getState().auth.user.token

    return await productService.viewProduct(prodid,token)

  } catch (error) {

    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message); //checks state.isError -> throws message

  }

})

// Delete user Product
export const deleteProduct = createAsyncThunk(
  'products/delete',
  async (prodid, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      return await productService.deleteProduct(prodid, token)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

// Update user Product
export const updateProduct = createAsyncThunk(
  'products/update',
  async ([productData,prodid], thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token

      // console.log(prodid);

      return await productService.updateProduct([productData,prodid], token)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

// SOLD user Product
export const soldProduct = createAsyncThunk("products/sold", async(prodid,thunkAPI)=>{
  try {
    const token = thunkAPI.getState().auth.user.token

    return await productService.soldProduct(prodid,token)

  } catch (error) {

    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message); //checks state.isError -> throws message

  }

})

// Get approvals page
export const getApprovalsPage = createAsyncThunk("products/getApprovalsPage", async(_,thunkAPI) =>{

  try {
    const token = thunkAPI.getState().auth.user.token

    // Call your service function here
    return await productService.getApprovalsPage(token)

  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message); //checks state.isError -> throws message
  }

})

// Get reviews page
export const getReviewsPage = createAsyncThunk("products/getReviewsPage", async(_,thunkAPI) =>{

  try {
    const token = thunkAPI.getState().auth.user.token

    // Call your service function here
    return await productService.getReviewsPage(token)

  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message); //checks state.isError -> throws message
  }

})

// Approve product
export const approveProduct = createAsyncThunk("products/approveProduct", async(productid,thunkAPI) =>{

  try {
    const token = thunkAPI.getState().auth.user.token

    return await productService.approveProduct(productid, token)

  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message); //checks state.isError -> throws message
  }

})

// Reject product
export const rejectProduct = createAsyncThunk("products/rejectProduct", async(productid,thunkAPI) =>{

  try {
    const token = thunkAPI.getState().auth.user.token

    return await productService.rejectProduct(productid, token)

  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message); //checks state.isError -> throws message
  }

})






export const productSlice = createSlice({
  name: "product",
  initialState,
  reducers:{
    resetProduct: (state) => initialState
  },
    extraReducers: (builder) =>{
      builder
      .addCase(createProduct.pending, (state)=>{
        state.isLoading = true
      })
      .addCase(createProduct.fulfilled, (state, action /*action will be pushed to the product array */)=>{
        state.isLoading = false
        state.isSuccess = true
        state.products.push(action.payload)
        state.prodid = action.payload.productid;
        state.isAdded = true;
      })
      .addCase(createProduct.rejected, (state, action /*action is the message*/ )=>{
        state.isLoading = false
        state.isError = true
        state.message = action.payload
        state.isAdded = false;
      })
      .addCase(getProducts.pending, (state)=>{
        state.isLoading = true
      })
      .addCase(getProducts.fulfilled, (state, action /*action will be pushed to the product array */)=>{
        state.isLoading = false
        state.isSuccess = true
        state.products = action.payload
      })
      .addCase(getProducts.rejected, (state, action /*action is the message*/ )=>{
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      })
      .addCase(searchAllProducts.pending, (state)=>{
        state.isLoading = true
      })
      .addCase(searchAllProducts.fulfilled, (state, action /*action will be pushed to the product array */)=>{
        state.isLoading = false
        state.isSuccess = true
        state.products = action.payload
      })
      .addCase(searchAllProducts.rejected, (state, action /*action is the message*/ )=>{
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      })
      .addCase(viewProduct.pending, (state)=>{
        state.isLoading = true
      })
      .addCase(viewProduct.fulfilled, (state, action /*action will be pushed to the product array */)=>{
        state.isLoading = false
        state.isSuccess = true
        // state.products = action.payload
        state.products = action.payload;
        state.prodid = action.payload.productid;
        // state.products.filter(
        //   (product) => product.productid !== Number(action.payload.id)
        // )
      })
      .addCase(viewProduct.rejected, (state, action /*action is the message*/ )=>{
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      })
      .addCase(updateProduct.pending, (state)=>{
        state.isLoading = true
      })
      .addCase(updateProduct.fulfilled, (state, action /*action will be pushed to the product array */)=>{
        state.isLoading = false
        state.isSuccess = true
        state.isUpdated = true
        state.products = action.payload;
        state.prodid = action.payload.productid;
        // state.prodid = action.payload;
        // state.products.filter(
        //   (product) => product.productid !== Number(action.payload.id)
        // )
      })
      .addCase(updateProduct.rejected, (state, action /*action is the message*/ )=>{
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      })

      .addCase(deleteProduct.pending, (state) => {
        state.isLoading = true
      })
      .addCase(deleteProduct.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.isDeleted = true
        state.message = action.payload;
        state.prodid = "";
        // state.products.filter(
        //   (product) => product.productid !== action.payload.productid && product.userid === action.payload.id
        // )
        // state.products = []
        // return {
        //   ...state,
        //   products: state.products
        // }
      })
      .addCase(deleteProduct.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      })

      .addCase(soldProduct.pending, (state) => {
        state.isLoading = true
      })
      .addCase(soldProduct.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.isUpdatedSoldRelist = true
        state.message = action.payload
      })
      .addCase(soldProduct.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      })
      .addCase(getApprovalsPage.pending, (state)=>{
        state.isLoading = true
      })
      .addCase(getApprovalsPage.fulfilled, (state, action)=>{
        state.isLoading = false
        state.isSuccess = true
        state.products = action.payload
      })
      .addCase(getApprovalsPage.rejected, (state, action)=>{
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      })
      .addCase(getReviewsPage.pending, (state)=>{
        state.isLoading = true
      })
      .addCase(getReviewsPage.fulfilled, (state, action)=>{
        state.isLoading = false
        state.isSuccess = true
        state.products = action.payload
      })
      .addCase(getReviewsPage.rejected, (state, action)=>{
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      })
      .addCase(approveProduct.pending, (state)=>{
        state.isLoading = true
      })
      .addCase(approveProduct.fulfilled, (state, action)=>{
        state.isLoading = false
        state.isSuccess = true
        state.isReviewed = true
        state.isApproved = true
        state.message = action.payload
        // Update the product in the state
      })
      .addCase(approveProduct.rejected, (state, action)=>{
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      })
      .addCase(rejectProduct.pending, (state)=>{
        state.isLoading = true
      })
      .addCase(rejectProduct.fulfilled, (state, action)=>{
        state.isLoading = false
        state.isSuccess = true
        state.isReviewed = true
        state.isApproved = false
        state.message = action.payload
        // Update the product in the state
      })
      .addCase(rejectProduct.rejected, (state, action)=>{
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      })


    }
})

export const {resetProduct} = productSlice.actions

export default productSlice.reducer
