import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, Link, useParams } from "react-router-dom";
import Spinner from "../components/Spinner";
import { toast } from "react-toastify";
import { FaArrowLeft } from "react-icons/fa";


import { getConversation, respondToConversation, resetMessage } from "../features/messages/messageSlice";
import { reset, logout } from "../features/auth/authSlice";

function ViewUserMessage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.auth);
  const { conversation, isLoading, isError, isSuccess, isResponse, message } = useSelector((state) => state.messages);

  let { id } = useParams();

  const [toggle, setToggle] = useState(false);
  const [formData, setFormData] = useState({ text: "" });

  useEffect(() => {
    if (isError) {
      toast.error(message);
      if (message.match(/(Token expired|Not authorized|no token)/g)) {
        dispatch(logout());
        navigate('/login', { replace: true });
      }
      dispatch(resetMessage());
    }

    if (isSuccess && isResponse) {
      toast.success(message);
      dispatch(resetMessage());
      navigate("/ViewMessages/");
    }

    if (!user) {
        dispatch(reset())
        navigate('/login', { replace: true });
    }

    dispatch(getConversation(id));
  }, [user, id, navigate, isSuccess, isResponse, isError, message, dispatch]);

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    dispatch(respondToConversation([id, formData]));
  };

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <>
    <section className="container">
      <div className="row justify-content-center align-items-center">
      <div className="messagetitle">
      This message is for your product: <Link className="nav-link text-success" to={"/viewProduct/" + conversation?.forProductid}>{conversation?.forProductname}</Link>
      </div>
        <div className="col">
          {conversation && conversation.messages && conversation.messages.map((message, index) => {
            if (typeof message === 'object' && message !== null) {
              return (
                <div key={index} className={`bubble bubble--${message.sender === user.id ? "right" : "left"}`}>
                  <strong>{message.senderusername}</strong>: {message.text}
                </div>
              );
            }
          })}
        </div>
      </div>
    </section>

      <button onClick={() => setToggle(!toggle)} className="btn btn-secondary me-2">
        Respond
      </button>

      {toggle && (
        <form onSubmit={onSubmit} encType='multipart/form-data'>
          <div className="form-group">
            <label htmlFor="text">Response</label>
            <textarea
              className="form-control"
              id="text"
              name="text"
              value={formData.text}
              placeholder="Enter response"
              onChange={onChange}
            />
          </div>

          <div className="form-group">
            <button className="btn btn-block btn-primary" type="submit">REPLY</button>
          </div>
        </form>
      )}

      <button className='btn btn-warning me-2' onClick={() => navigate(-1)}>
        <FaArrowLeft />
      </button>
    </>
  );
}

export default ViewUserMessage;
