import {FaSignInAlt,FaSignOutAlt,FaUser,FaSearch} from "react-icons/fa";
import {Link, useNavigate} from "react-router-dom";
import {useSelector, useDispatch} from "react-redux";
import {logout, reset, resetProfile} from "../features/auth/authSlice";
import logo from "../logos/logo192.png";

function Header() {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);

  const onLogout = async() => {
    await dispatch(logout())
    await dispatch(reset())
    await dispatch(resetProfile())
    navigate('/');
  }

  return(
    <header>
        <section className="wavynavcontainer">
            <section className="logocontainer">
                <Link className="nav-link" to="/"><img className="logo" src={logo} alt="jinglelogo" /></Link>
            </section>

            <section className="navlinks">
                <Link className="nav-link" to="/">Home</Link>
                <Link className="nav-link" to="/about">About</Link>
                <Link className="nav-link" to="/contact">Contact</Link>
            </section>

            <section className="navlinks">
                {user ? (
                  <>
                    <Link className="nav-link" to="/searchProducts"><FaSearch/> Search</Link>
                    <Link className="nav-link" to="/dashboard">Dashboard</Link>
                    <Link className="nav-link mt-3" onClick={onLogout}><FaSignOutAlt/> Logout</Link>
                  </>
                ) : (
                  <>
                    <Link className="nav-link" to='/login'>
                      <FaUser /> Login
                    </Link>
                    <Link className="nav-link" to='/register'>
                      <FaSignInAlt/> Register
                    </Link>
                  </>
                )}
            </section>
        </section>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#00cba9" fillOpacity="1" d="M0,96L48,85.3C96,75,192,53,288,58.7C384,64,480,96,576,101.3C672,107,768,85,864,69.3C960,53,1056,43,1152,53.3C1248,64,1344,96,1392,112L1440,128L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z"></path></svg>
    </header>
  )
}

export default Header
