import {useState,useEffect} from "react";
import {useSelector,useDispatch} from "react-redux";
import {useNavigate,useParams} from "react-router-dom";
import {FaBox} from "react-icons/fa";
import Spinner from "../components/Spinner";
import {toast} from "react-toastify";


import {updateProduct,viewProduct,deleteProduct,resetProduct} from "../features/products/productSlice";
import {reset, logout} from "../features/auth/authSlice";


function ProductUpdate(){

  const navigate = useNavigate();
  const dispatch = useDispatch();


  const {user} = useSelector((state => state.auth));

  const {products,isLoading,isSuccess, isError,isUpdated, isDeleted, message} = useSelector((state => state.products));

  let { id } = useParams();

//UPDATE PRODUCT

  useEffect(()=>{


    if (isError) {

      toast.error(message);
      // console.log(message);
      if (message.match(/(Token expired|Not authorized|no token)/g)) {

        dispatch(logout());
        navigate('/login', { replace: true });

      }

      dispatch(resetProduct())
    }

    if (isSuccess && isUpdated) {
      toast.success("Product updated")
      dispatch(resetProduct())
      navigate("/viewProduct/" + id)
    }

    if (!id) {
      navigate("/searchProducts")
      dispatch(resetProduct())
    }

    if (!user) {
        dispatch(reset())
        navigate('/login', { replace: true });
    }

    if (isSuccess && isDeleted) {
      toast.success("product deleted")
        dispatch(resetProduct())
        navigate("/viewUserProducts")

      // console.log(message);


    }



    dispatch(viewProduct(id));


}, [user,id,navigate,isSuccess,isUpdated,isDeleted,isError,message,dispatch])




const [productData, setFormData] = useState({
    name: products.productName,
    description:products.productDescription,
    price:products.productPrice,
  });

  const {name,description,price} = productData;



const onSubmit = e => {
  e.preventDefault();

  dispatch(updateProduct([productData,id]));

  }


  const onChange = (e) => {
    setFormData((prevState) => ({
        ...prevState,
        [e.target.name]:e.target.value //key of whatever the target "name" is (input name prop)

      }))
    };

    const handleDelete = (thisid) => {
      dispatch(deleteProduct(thisid));
      // dispatch(reset())
    }


    if (isLoading) {
      return <Spinner />
    }

    // <input type="text" value={products.productZIP} disabled/>
    return (
      <>
      <section className="form">

      <h5><FaBox />Product Update Form</h5>

      <form onSubmit={onSubmit} encType='multipart/form-data'>


      <div className="form-group">
        <label htmlFor="name">Name</label>
        <input
        type="text"
        className="form-control"
        id="name"
        name="name"
        value={name}
        placeholder="Enter New Product name"
        onChange={onChange}
        />
      </div>

      <div className="form-group">
        <label htmlFor="description">Description</label>
        <textarea
        className="form-control"
        id="description"
        name="description"
        value={description}
        placeholder="Enter a new Description"
        onChange={onChange}></textarea>
      </div>

      <div className="form-group">
        <label htmlFor="price">Price</label>
        <input
        className="form-control"
        type="number"
        id="price"
        name="price"
        value={price}
        placeholder="Enter a new price"
        onChange={onChange}/>
      </div>



      <div className="productactions">
        <button className="btn btn-block btn-success mx-1" type="submit">Update product</button>
        <button className='btn btn-danger mx-1' onClick={() => handleDelete(products.productid)}>
        Delete
        </button>
        <button className='btn btn-block btn-warning mx-1' onClick={() => navigate("/viewUserProducts")}>
        Cancel
        </button>
      </div>


      </form>

      </section>
      </>
    )
}

export default ProductUpdate
